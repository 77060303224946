a {
  color: inherit;
}

.container {
  position: relative;
}

.page {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  transition: opacity 300ms, transform 300ms;
}

.page-enter {
  opacity: 0;
  /* transform: scale(1.1); */
  transform: translate3d(100px, 0, 0);
}

.page-enter-active {
  opacity: 1;
  /* transform: scale(1); */
  transform: translate3d(0, 0, 0);
  /* transition: opacity 300ms, transform 300ms; */
}

.page-exit {
  opacity: 1;
  transform: scale(1);
  transform: translate3d(0, 0, 0);
}

.page-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transform: translate3d(-100px, 0, 0);
  /* transition: opacity 300ms, transform 300ms; */
}
